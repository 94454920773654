import React, { PropsWithChildren, ReactElement } from 'react'
import { ParagraphMedium, ParagraphLarge } from 'baseui/typography'
import { HeadingLevel, Heading } from 'baseui/heading'
import { StyledLink } from 'baseui/link'
import { useStyletron } from 'baseui'
import { ourColors } from './Colors'
import { DocumentsResponse, ImagesResponse, getDocument, getImage } from '../api/cms'
import { UserContext } from './Layout'
import { VimeoVideo } from './VimeoVideo'
import constants from '../constants'
import { HorizontalLine } from './Line'

const ActTag = (props: any) => {
  const [css] = useStyletron()
  const boxStyles = {
    backgroundColor: '#FAD0DF',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '10px',
    paddingRight: '10px',
    display: 'inline-block',
    textDecorationLine: 'none',
    fontSize: '18px',
    color: ourColors.copyGrey
  }
  console.log(props.children)
  return props.children ? (
    <span className={css(boxStyles)} data-testid="acttag">
      {props.children}
    </span>
  ) : null
}

interface DocLinkProps {
  id: string
}

const DocLink = ({ id, children }: PropsWithChildren<DocLinkProps>) => {
  const { token } = React.useContext(UserContext)
  const [docUrl, setDocUrl] = React.useState('')

  React.useEffect(() => {
    if (token) {
      getDocument(token, id).then((response: DocumentsResponse) => {
        if (response.meta.total_count > 0) {
          setDocUrl(response.items[0].meta.download_url)
        }
      })
    }
  }, [token, id])

  const outputElement = docUrl ? (
    <StyledLink href={docUrl} target="_blank" rel="noopener noreferrer">
      {children}
    </StyledLink>
  ) : (
    <>children</>
  )

  return outputElement
}

interface ImageProps {
  id: string
  altText: string
}

const Image = ({ id, altText }: ImageProps) => {
  const [css] = useStyletron()
  const { token } = React.useContext(UserContext)
  const [imageUrl, setImageUrl] = React.useState('')

  React.useEffect(() => {
    if (token) {
      getImage(token, id).then((response: ImagesResponse) => {
        if (response.meta.total_count > 0) {
          let downloadUrl = response.items[0].meta.download_url
          if (process.env.NODE_ENV !== 'production') {
            downloadUrl = constants.cmsUrl + downloadUrl
          }
          setImageUrl(downloadUrl)
        }
      })
    }
  }, [token, id])

  let outputElement = null
  if (imageUrl) {
    const imageStyles = css({
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block'
    })
    outputElement = <img src={imageUrl} alt={altText} className={imageStyles} />
  }

  return outputElement
}

interface EmbedElementProps {
  attributes: NamedNodeMap
}

const EmbedElement = ({ attributes }: EmbedElementProps) => {
  let outputElement = null
  const embedtype = attributes.getNamedItem('embedtype')
  if (embedtype) {
    if (embedtype.value === 'image') {
      const id = attributes.getNamedItem('id')
      const altText = attributes.getNamedItem('alt')
      if (id) {
        outputElement = <Image id={id.value} altText={altText ? altText.value : ''} />
      }
    } else if (embedtype.value === 'media') {
      const url = attributes.getNamedItem('url')
      if (url) {
        outputElement = <VimeoVideo videoUrl={url.value} />
      }
    }
  }
  return outputElement
}

interface GetElementFromRichTextTagProps {
  tag: string
  attributes: NamedNodeMap
  forAccordion?: boolean
}

const GetElementFromRichTextTag = ({
  tag,
  attributes,
  forAccordion,
  children
}: PropsWithChildren<GetElementFromRichTextTagProps>) => {
  const [css] = useStyletron()
  const listStyles = css({
    fontFamily: 'Roboto, sans-serif',
    fontSize: forAccordion ? '16px' : '18px',
    color: ourColors.copyGrey
  })
  const passTheseElementsThrough = ['B', 'I', 'LI']

  let outputElement = <>{children}</>

  if (tag === 'P') {
    if (forAccordion) {
      outputElement = <ParagraphMedium>{children}</ParagraphMedium>
    } else {
      outputElement = <ParagraphLarge>{children}</ParagraphLarge>
    }
  } else if (passTheseElementsThrough.includes(tag)) {
    const LowerCaseTag = tag.toLowerCase() as keyof JSX.IntrinsicElements
    outputElement = <LowerCaseTag>{children}</LowerCaseTag>
  } else if (tag === 'H2' || tag === 'H3' || tag === 'H4') {
    outputElement = (
      <HeadingLevel>
        <Heading styleLevel={forAccordion ? 4 : 3}>{children}</Heading>
      </HeadingLevel>
    )
  } else if (tag === 'A') {
    const href = attributes.getNamedItem('href')
    const id = attributes.getNamedItem('id')
    const linktype = attributes.getNamedItem('linktype')
    if (href) {
      outputElement = (
        <StyledLink href={href.value} target="_blank" rel="noopener noreferrer">
          {children}
        </StyledLink>
      )
    } else if (id && linktype && linktype.value === 'document') {
      outputElement = <DocLink id={id.value}>{children}</DocLink>
    }
  } else if (tag === 'UL') {
    outputElement = <ul className={listStyles}>{children}</ul>
  } else if (tag === 'OL') {
    outputElement = <ol className={listStyles}>{children}</ol>
  } else if (tag === 'EMBED') {
    outputElement = <EmbedElement attributes={attributes} />
  } else if (tag === 'BR') {
    outputElement = <br />
  } else if (tag === 'HR') {
    outputElement = <HorizontalLine />
  } else if (tag === 'ACTTAG') {
    outputElement = <ActTag>{children}</ActTag>
  }

  return outputElement
}

interface RichTextAsElementProps {
  richText: string
  depth: number
  forAccordion?: boolean
}

export const RichTextAsElement = ({ richText, depth, forAccordion }: RichTextAsElementProps) => {
  const outputElementArray: Array<ReactElement> = []
  // document is unavailable during server-side rendering
  const richTextContainer = typeof window !== 'undefined' ? document.createElement('div') : null

  if (!richTextContainer) {
    return null
  }

  richTextContainer.innerHTML = richText
  const richTextNodes = richTextContainer.childNodes

  richTextNodes.forEach((node, index) => {
    const richTextNode = node as HTMLElement
    const key = depth + '-' + index
    if (richTextNode.nodeType === Node.TEXT_NODE) {
      outputElementArray.push(<React.Fragment key={key}>{richTextNode.textContent}</React.Fragment>)
    } else if (richTextNode.nodeType === Node.ELEMENT_NODE) {
      if (richTextNode.tagName) {
        outputElementArray.push(
          <GetElementFromRichTextTag
            tag={richTextNode.tagName}
            attributes={richTextNode.attributes}
            key={key}
            forAccordion={forAccordion}
          >
            <RichTextAsElement
              richText={richTextNode.innerHTML}
              depth={depth + 1}
              forAccordion={forAccordion}
            />
          </GetElementFromRichTextTag>
        )
      }
    }
  })

  return <>{outputElementArray}</>
}
