import React from 'react'
import { Layout, UserContext } from '../components/Layout'

import { MainContent } from '../components/MainContent'
import { HeadingLevel } from 'baseui/heading'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { Heading } from '../components/Heading'
import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { getQueryVariable, handleErrorFromAPI } from '../utils'
import { ConditionPageResponse, getConditionsData } from '../api/cms'
import { ErrorNotification } from '../components/Notification'

interface ConditionsPageHeaderProps {
  pageTitle: string
}
const ConditionsPageHeader = ({ pageTitle }: ConditionsPageHeaderProps) => {
  return (
    <Grid align={ALIGNMENT.center}>
      <Cell span={[4, 6]}>
        <HeadingLevel>
          <Heading data-testid="pageTitle" overrides={{ Block: { style: { marginBottom: 0 } } }}>
            {pageTitle}
          </Heading>
        </HeadingLevel>
      </Cell>
    </Grid>
  )
}

interface ConditionsPageProps {
  location: WindowLocation
}
export const ConditionsPage = (props: ConditionsPageProps) => {
  const { token, setUserContext } = React.useContext(UserContext)
  const [pageTitle, setPageTitle] = React.useState('')
  const [text, setText] = React.useState('')
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const titleSearchString = getQueryVariable('title', props.location.search)

  React.useEffect(() => {
    if (!titleSearchString) {
      navigate('/404', { replace: true })
    }

    if (titleSearchString) {
      getConditionsData(titleSearchString)
        .then((response: ConditionPageResponse) => {
          if (response.meta.total_count > 0) {
            const content = response.items[0]
            setPageTitle(content.title)
            setText(content.text)
          } else {
            navigate('/404', { replace: true })
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, titleSearchString, setUserContext])

  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <ErrorNotification hasError={loadingErrorActive} />
        </Cell>
      </Grid>
      {titleSearchString && pageTitle && <ConditionsPageHeader pageTitle={pageTitle} />}
      <MainContent richText={text} />
    </>
  )
}

interface LayoutConditionsPageProps {
  location: WindowLocation
}
const LayoutConditionsPage = (props: LayoutConditionsPageProps) => {
  return (
    <Layout>
      <ConditionsPage location={props.location} />
    </Layout>
  )
}

export default LayoutConditionsPage
