import { Grid, Cell } from 'baseui/layout-grid'
import React from 'react'
import { useStyletron } from 'baseui'

interface VimeoVideoProps {
  videoUrl: string
  desktopSpacing?: number
}
export const VimeoVideo = ({ videoUrl, desktopSpacing }: VimeoVideoProps) => {
  const [css] = useStyletron()

  // fix up the margins in other users
  return (
    <Grid gridMargins={[0, desktopSpacing || 16]} gridGutters={[0, desktopSpacing || 16]}>
      <Cell span={[4, 6]}>
        <div className={css({ paddingTop: '56.25%', position: 'relative' })}>
          <iframe
            src={videoUrl + '?title=0&byline=0&portrait=0'}
            className={css({
              position: 'absolute',
              border: 'none',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            })}
          />
        </div>
      </Cell>
    </Grid>
  )
}
