import React from 'react'
import { useStyletron } from 'baseui'
import { Grid, Cell } from 'baseui/layout-grid'
import { RichTextAsElement } from './RichTextAsElement'

interface MainContentProps {
  richText: string
}
export const MainContent = ({ richText }: MainContentProps) => {
  const [css] = useStyletron()

  return (
    <Grid>
      <Cell span={[4, 6]}>
        <div className={css({ marginTop: '22px' })}>
          <RichTextAsElement richText={richText} depth={0} />
        </div>
      </Cell>
    </Grid>
  )
}
